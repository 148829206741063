import { DynamicFieldData } from "./types";

export const generativeFields: DynamicFieldData[] = [
  {
    fieldName: "gpu_count",
    inputType: "select",
    label: "Number of GPUs",
    options: [
        { value: 1, label: 'One'}, 
        { value: 2, label: 'Two'}
    ],
    defaultValue: "",
    helpContent: "Some generative applications can make use of multiple GPUs installed in a system, using a high speed bridge connection between the cards. Not all applications can make use of this bridged GPU technology.",
    config: {
      required: "You must select the number of GPUs"
    }
  },
  {
    fieldName: "ram",
    inputType: "select",
    label: "RAM",
    options: [
        { value: '64GB', label: '64GB'}, 
        { value: '128GB', label: '128GB'}
    ],
    defaultValue: '',
    helpContent: "We recommend using 128 GB of RAM in generative applications, as it allows more geometry to be held in memory for real-time rendering.",
    config: {
      required: "You must select to required RAM"
    }
  },
  {
    fieldName: "media_drive_size",
    inputType: "select",
    label: "Media Drive size",
    options: [
        { value: '1800', label: '1800GB'}, 
        { value: '3600', label: '3600GB'},
        { value: '7200', label: '7200GB'}
    ],
    defaultValue: '',
    helpContent: "Generative applications are usually much smaller than frame-based playback, but they can still use big asset files like texture maps or even embedded video elements.",
    config: {
      required: "You must select a Media Drive size"
    }
  },
  {
    fieldName: "genlock_required",
    inputType: "toggle",
    label: "Is Genlock or Framelock required",
    helpContent: "The synchronisation of devices by linking them to a common timing signal. This is required if pictures from multiple pieces of equipment need to be drawn at precisely the same time. For example, if you have overlapped projectors, if the images aren’t drawn at the same time, the image in the overlap zone can “tear” or become blurry when there is motion in the content. Similarly, feeding signal from different media servers to adjacent parts of an LED wall can result in image tearing if the servers and LED processors are not synchronized with a Genlock signal.",
    defaultValue: true
  },
  {
    fieldName: "comments",
    inputType: "textarea",
    label: "Additional information (optional)",
    defaultValue: ""
  },
];

export const mediaFields: DynamicFieldData[] = [
  {
    fieldName: "output_number",
    inputType: "number",
    label: "How many video outputs are required?",
    defaultValue: 1,
    helpContent: "Also known as “output channels,” this defines how many physical video outputs are required.",
    config: {
      required: "You must enter a number of outputs",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },
  {
    fieldName: "output_types",
    inputType: "select",
    label: "What type of outputs",
    helpContent: "NDI ®: Network Device Interface developed by NewTek. A visually lossless royalty-free IP standard for allowing multiple video streams on a shared connection. NDI supports high quality, low latency, frame-accurate video over standard Ethernet networks.",
    options: [
        { value: "DisplayPort", label: 'DisplayPort'}, 
        { value: "HDMI", label: 'HDMI'},
        { value: "SDI", label: 'SDI'},
        { value: "NDI", label: 'NDI'},
        { value: "ST 2110", label: 'ST 2110'},
    ],
    defaultValue: "",
    config: {
      required: "You must select an output type"
    }
  },
  {
    fieldName: "output_resolution",
    inputType: "select",
    label: "What is the resolution of each output?",
    comment: "All outputs on a server must have the same resolution",
    options: [
        { value: '4096x2160', label: '4K (4096 x 2160)'}, 
        { value: '3840x2160', label: 'UHD (3840 x 2160)'},
        { value: '1920x1200', label: 'WUXGA (1920 x 1200)'},
        { value: '1920x1080', label: 'HD (1920 x 1080)'},
        { value: 'custom', label: 'Custom'}
	  ],
    defaultValue: '',
    helpContent: "Resolution defines the pixel dimensions of the outputs. Some types of output like SDI and ST 2110 are restricted to fixed output resolutions (HD, 2K, UHD, and 4K), whereas DisplayPort and HDMI are able to be configured to custom resolutions.",
    config: {
      required: "You must select a resolution"
    }
  },
  {
    parent: "output_resolution",
    fieldName: "custom_output_width",
    inputType: "number",
    label: "Width",
    defaultValue: "",
    config: {
      required: "You must select a width",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },
  {
    parent: "output_resolution",
    fieldName: "custom_output_height",
    inputType: "number",
    label: "Height",
    defaultValue: "",
    config: {
      required: "You must select a height",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },
  {
    fieldName: "output_frame_rate",
    inputType: "select",
    label: "What is the refresh rate of the outputs?",
    options: [
      { value: '23.98', label: '23.98'}, 
      { value: '24', label: '24'}, 
      { value: '25', label: '25'},
      { value: '29.97', label: '29.97'},
      { value: '30', label: '30'},
      { value: '48', label: '48'},
      { value: '50', label: '50'},
      { value: '59.94', label: '59.94'},
      { value: '60', label: '60'},
      { value: '119.88', label: '119.88'},
      { value: '120', label: '120'},
      { value: '240', label: '240'},
	  ],
    defaultValue: '',
    helpContent: "This defines the output refresh rate. All outputs must use the same refresh rate. It is possible to have a different output refresh rate than your media frame rate, though for best results it is recommended that the output refresh rate match the media frame rate.",
    config: {
      required: "You must select a frame rate"
    }
  },
  {
    fieldName: "bit_rate",
    inputType: "select",
    label: "What bit depth are the outputs?",
    helpContent: "Bit depth refers to how detailed each pixel’s colour information is. 8-bit colour has 256 steps between black and full value for each colour whereas 10-bit colour has 1024 steps across the same range. Higher bit depth means more information must be stored, processed, and transmitted for each pixel, increasing the bandwidth requirements for media drives and graphics cards.",
    options: [
        { value: 8, label: '8-bit'}, 
        { value: 10, label: '10-bit'},
        { value: 12, label: '12-bit'}
	  ],
    defaultValue: "",
    config: {
      required: "You must select a bit depth"
    }
  },
  {
    fieldName: "movie_count",
    inputType: "select",
    label: "How many movies will be playing simultaneously?",
    helpContent: "When multiple movies need to be played at the same time, such as when cross-fading between playing movies, multiple files need to be read from the media drives at the same time and sent to the graphics cards for processing. This impacts the selection of media drives because it can easily double or triple the bandwidth requirement of the drives.",
    options: [
        { value: 1, label: '1'}, 
        { value: 2, label: '2'},
        { value: 3, label: '3'},
        { value: 4, label: '4'},
        { value: 5, label: '5'},
        { value: 6, label: '6'},
        { value: 7, label: '7'},
        { value: 8, label: '8'},
        { value: 9, label: '9'},
        { value: 10, label: '10'},
        { value: 11, label: '11'},
        { value: 12, label: '12'},
	  ],
    defaultValue: "",
    config: {
      required: "You must select a movie count"
    }
  },
  {
    fieldName: "movie_resolution",
    inputType: "select",
    label: "What is the resolution of each movie?",
    helpContent: "Select the single highest resolution output of the media. If it needs be a custom resolution output, please contact us at <a href=\"mailto:info@7thsense.one\">info@7thsense.one</a>",
    options: [
        { value: '4096x2160', label: '4K (4096 x 2160)'}, 
        { value: '3840x2160', label: 'UHD (3840 x 2160)'},
        { value: '1920x1200', label: 'WUXGA (1920 x 1200)'},
        { value: '1920x1080', label: 'HD (1920 x 1080)'},
        { value: 'custom', label: 'Custom'}
	  ],
    defaultValue: '',
    config: {
      required: "You must select a resolution"
    }
  },
  {
    parent: "movie_resolution",
    fieldName: "custom_movie_width",
    inputType: "number",
    label: "Width",
    defaultValue: "",
    config: {
      required: "You must select a width",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },
  {
    parent: "movie_resolution",
    fieldName: "custom_movie_height",
    inputType: "number",
    label: "Height",
    defaultValue: "",
    config: {
      required: "You must select a height",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },
  {
    fieldName: "movie_frame_rate",
    inputType: "select",
    label: "What is the frame rate of each movie?",
    helpContent: "The more frames per second, the more media drive space is required.",
    options: [
      { value: '23.98', label: '23.98'}, 
      { value: '24', label: '24'}, 
      { value: '25', label: '25'},
      { value: '29.97', label: '29.97'},
      { value: '30', label: '30'},
      { value: '48', label: '48'},
      { value: '50', label: '50'},
      { value: '59.94', label: '59.94'},
      { value: '60', label: '60'},
      { value: '119.88', label: '119.88'},
      { value: '120', label: '120'},
      { value: '240', label: '240'},
	  ],
    defaultValue: '',
    config: {
      required: "You must select a frame rate"
    }
  },
  {
    fieldName: "colour_subsampling",
    inputType: "select",
    label: "What is the chroma subsampling for the media?",
    helpContent: `
      NotchLC: A visually lossless codec that achieves compression rates between 4:1 and 8:1. <br />
      4:4:4 represents every pixel with full colour data for each pixel. Most computer generated graphics use 4:4:4.<br />
      4:4:4:4 is the same as 4:4:4 but it includes another channel for alpha, or transparency. This format is often used for media which must be played on top of other media, like lower thirds. <br />
      4:2:2 represents luma for every pixel, but chroma information is only stored for every other pixes. Most cameras capture using 4:2:2<br />
      4:2:0 represents luma for ever pixel, but chroma information is only stored for every 4 pixels, greatly reducing file size but sacrificing image integrity. 
    `,
    options: [
      { value: '4:4:4', label: '4:4:4'}, 
      { value: '4:4:4:4 (with alpha)', label: '4:4:4:4 (with alpha)'},
      { value: '4:2:2', label: '4:2:2'},
      { value: '4:2:0', label: '4:2:0'},
      { value: 'NotchLC', label: 'NotchLC'},
	  ],
    defaultValue: '',
    config: {
      required: "You must select an option"
    }
  },
  {
    fieldName: "total_duration",
    inputType: "number",
    label: "What is the total duration in minutes of media across all outputs to be stored on the server?",
    helpContent: "A 5-minute movie spanning all 4 outputs of a 4 output server would answer 5 minutes to this question",
    defaultValue: "",
    config: {
      required: "You must enter a duration",
      validate: (value) => value >= 1 || "Number must be positive"
    }
  },

  {
    fieldName: "display_type",
    inputType: "select",
    label: "What display type will be connected to the media server? ",
    helpContent: "Single displays, blended projection on a flat surface, projection mapping on a 3D object, and oddly shaped LED walls all have different requirements, so this helps us to understand what you are trying to achieve with media servers.",
    options: [
      { value: 'LED Wall', label: 'LED Wall'}, 
      { value: 'LCD display', label: 'LCD display'},
      { value: 'Projection (blended)', label: 'Projection (blended)'},
      { value: 'Projection (not blended', label: 'Projection (not blended)'},
	  ],
    defaultValue: '',
    config: {
      required: "You must select a display type"
    }
  },
  {
    fieldName: "capture_required",
    inputType: "toggle",
    label: "Is Capture is required",
    helpContent: "Video capture allows external video sources to be used in the composition of the canvas in the media server, providing a way to show live camera feeds, captured imagery from other computers, or streamed media from the internet. Live capture resources act like any other visual media, so they can be sized, mixed, warped and have many special effects applied to them.",
    defaultValue: 'yes'
  },
  {
    parent: "capture_required",
    fieldName: "types_of_input",
    inputType: "select",
    label: "What types of input?",
    options: [
      { value: "DisplayPort", label: 'DisplayPort'}, 
      { value: "HDMI", label: 'HDMI'},
      { value: "SDI", label: 'SDI'},
      { value: "ST 2110", label: 'ST 2110'},
      { value: "NDI", label: 'NDI'},
    ],
    defaultValue: "",
    config: {
      required: "You must select an input type"
    }
  },
  {
    parent: "capture_required",
    fieldName: "number_of_inputs",
    inputType: "select",
    label: "How many inputs?",
    options: [
      { value: 1, label: 'One'}, 
      { value: 2, label: 'Two'},
      { value: 3, label: 'Three'},
      { value: 4, label: 'Four'}
	  ],
    defaultValue: "",
    config: {
      required: "You must select a number of inputs required"
    }
  },
  {
    fieldName: "genlock_required",
    inputType: "toggle",
    label: "Is Genlock is required",
    helpContent: "The synchronisation of devices by linking them to a common timing signal. This is required if pictures from multiple pieces of equipment need to be drawn at precisely the same time. For example, if you have overlapped projectors, if the images aren’t drawn at the same time, the image in the overlap zone can “tear” or become blurry when there is motion in the content. Similarly, feeding signal from different media servers to adjacent parts of an LED wall can result in image tearing if the servers and LED processors are not synchronized with a Genlock signal.",
    defaultValue: false
  },
  {
    fieldName: "audio_output",
    inputType: "select",
    label: "Audio Output?",
    options: [
      { value: '8U', label: '8-channel unbalanced analogue [8U]'},
      { value: '8BP', label: '8 channel balanced analog [8BP]'},
      { value: '16BP', label: '16 channel balanced analog [16BP]'},
      { value: '16A', label: '16 channel AES-EBU [16A]'},
      { value: '32DS', label: '32 channel Dante [32DS]'},
	  ],
    defaultValue: '',
    helpContent: "There are a variety of audio output options available on R-Series hardware. The standard configuration is 8 channels of unbalanced analog audio.",
    config: {
      required: "You must select an option"
    }
  },
  {
    fieldName: "ltc_chase",
    inputType: "toggle",
    label: "LTC Chase",
    defaultValue: false,
    helpContent: "Longitudinal Time Code, also known as SMPTE Time Code is a standard for sharing the absolute frame position of media among many AV devices. This switch defines whether your server needs to chase incoming time code."
  },
];