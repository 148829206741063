import { Link } from 'react-router-dom';
import WrapperComponent from '../wrapper/wrapper.component'
import { Footer, FooterGrid, FooterLegal, FooterSocial, LogoBox, SocialList } from './footer.styles'
import { ReactComponent as Logo } from '../header/logo.svg';
import { FadeInWhenVisible } from '../../routes/product/product.route';

const FooterComponent = () => {
    const year = new Date().getFullYear();
    return (
        <Footer>
            <WrapperComponent>
                <FooterGrid>
                    <FadeInWhenVisible>
                        <LogoBox>
                            <Link to="https://7thsense.one">
                                <Logo />
                            </Link>
                        </LogoBox>
                    </FadeInWhenVisible>

                    <FooterSocial>
                        <FadeInWhenVisible>
                            <SocialList>
                                <li>
                                    <Link to="https://www.facebook.com/7thsensedesign" target="_blank" aria-label="View our Facebook">                            
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Group" transform="translate(-0.000500, 0.000000)" fill="currentColor">
                                                    <path d="M30.6672,0 L1.3342,0 C0.5972,0 0.0002,0.597 0.0002,1.333 L0.0002,30.667 C0.0002,31.403 0.5972,32 1.3342,32 L17.0002,32 L17.0002,20 L13.0002,20 L13.0002,15 L17.0002,15 L17.0002,11 C17.0002,6.867 19.6172,4.833 23.3042,4.833 C25.0702,4.833 26.5882,4.965 27.0302,5.023 L27.0302,9.343 L24.4732,9.344 C22.4682,9.344 22.0002,10.297 22.0002,11.695 L22.0002,15 L27.0002,15 L26.0002,20 L22.0002,20 L22.0802,32 L30.6672,32 C31.4032,32 32.0002,31.403 32.0002,30.667 L32.0002,1.333 C32.0002,0.597 31.4032,0 30.6672,0" id="Fill-3"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/7thSenseDesign" target="_blank" aria-label="View our Twitter">
                                        <svg width="32px" height="26px" viewBox="0 0 32 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Group" transform="translate(-52.000500, -3.000000)" fill="currentColor">
                                                    <path d="M80.2295,7.1096 C81.5855,6.2966 82.6265,5.0106 83.1165,3.4776 C81.8485,4.2296 80.4425,4.7756 78.9475,5.0706 C77.7495,3.7946 76.0435,2.9976 74.1555,2.9976 C70.5295,2.9976 67.5905,5.9366 67.5905,9.5626 C67.5905,10.0766 67.6485,10.5776 67.7605,11.0586 C62.3035,10.7846 57.4665,8.1706 54.2285,4.1986 C53.6635,5.1686 53.3395,6.2966 53.3395,7.4996 C53.3395,9.7766 54.4985,11.7866 56.2595,12.9636 C55.1835,12.9296 54.1715,12.6346 53.2865,12.1426 C53.2855,12.1706 53.2855,12.1976 53.2855,12.2256 C53.2855,15.4066 55.5485,18.0596 58.5525,18.6626 C58.0015,18.8126 57.4215,18.8926 56.8225,18.8926 C56.3995,18.8926 55.9885,18.8516 55.5875,18.7756 C56.4225,21.3836 58.8475,23.2816 61.7205,23.3346 C59.4735,25.0956 56.6425,26.1446 53.5665,26.1446 C53.0365,26.1446 52.5145,26.1136 52.0005,26.0536 C54.9055,27.9156 58.3565,29.0026 62.0645,29.0026 C74.1405,29.0026 80.7435,18.9986 80.7435,10.3236 C80.7435,10.0386 80.7375,9.7556 80.7245,9.4736 C82.0075,8.5486 83.1205,7.3926 84.0005,6.0756 C82.8235,6.5976 81.5575,6.9506 80.2295,7.1096 Z" id="Fill-1"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/7thsense-design-ltd/" target="_blank" aria-label="View our Linked In">
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Group" transform="translate(-156.000500, -52.000000)" fill="currentColor">
                                                    <path d="M183.2692,79.2674 L178.5232,79.2674 L178.5232,71.8414 C178.5232,70.0704 178.4932,67.7924 176.0572,67.7924 C173.5882,67.7924 173.2112,69.7224 173.2112,71.7144 L173.2112,79.2674 L168.4702,79.2674 L168.4702,63.9964 L173.0202,63.9964 L173.0202,66.0844 L173.0852,66.0844 C173.7182,64.8834 175.2662,63.6174 177.5752,63.6174 C182.3812,63.6174 183.2692,66.7794 183.2692,70.8924 L183.2692,79.2674 Z M163.1192,61.9104 C161.5942,61.9104 160.3652,60.6774 160.3652,59.1574 C160.3652,57.6384 161.5942,56.4054 163.1192,56.4054 C164.6372,56.4054 165.8702,57.6384 165.8702,59.1574 C165.8702,60.6774 164.6372,61.9104 163.1192,61.9104 L163.1192,61.9104 Z M165.4932,79.2674 L160.7422,79.2674 L160.7422,63.9964 L165.4932,63.9964 L165.4932,79.2674 Z M186.6672,52.0004 L157.3342,52.0004 C156.5972,52.0004 156.0002,52.5974 156.0002,53.3334 L156.0002,82.6664 C156.0002,83.4034 156.5972,84.0004 157.3342,84.0004 L186.6672,84.0004 C187.4032,84.0004 188.0002,83.4034 188.0002,82.6664 L188.0002,53.3334 C188.0002,52.5974 187.4032,52.0004 186.6672,52.0004 L186.6672,52.0004 Z" id="Fill-33"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/7thsense00/" target="_blank" aria-label="View our Instagram">
                                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Group" transform="translate(-364.000500, 0.000000)" fill="currentColor">
                                                    <path d="M392.0005,29 L368.0005,29 C367.4485,29 367.0005,28.552 367.0005,28 L367.0005,13 L371.0005,13 C370.4795,13.75 370.3035,15.149 370.3035,16.037 C370.3035,21.392 374.6705,25.749 380.0375,25.749 C385.4055,25.749 389.7725,21.392 389.7725,16.037 C389.7725,15.149 389.6465,13.771 389.0005,13 L393.0005,13 L393.0005,28 C393.0005,28.552 392.5525,29 392.0005,29 M380.0375,9.898 C383.4365,9.898 386.1915,12.647 386.1915,16.037 C386.1915,19.428 383.4365,22.177 380.0375,22.177 C376.6395,22.177 373.8845,19.428 373.8845,16.037 C373.8845,12.647 376.6395,9.898 380.0375,9.898 M388.0005,4 L391.0005,4 C391.5525,4 392.0005,4.448 392.0005,5 L392.0005,8 C392.0005,8.552 391.5525,9 391.0005,9 L388.0005,9 C387.4485,9 387.0005,8.552 387.0005,8 L387.0005,5 C387.0005,4.448 387.4485,4 388.0005,4 M392.1965,0 L367.8045,0 C365.7035,0 364.0005,1.699 364.0005,3.795 L364.0005,28.205 C364.0005,30.301 365.7035,32 367.8045,32 L392.1965,32 C394.2975,32 396.0005,30.301 396.0005,28.205 L396.0005,3.795 C396.0005,1.699 394.2975,0 392.1965,0" id="Fill-23"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>
                                </li>
                            </SocialList>
                        </FadeInWhenVisible>
                    </FooterSocial>

                    <FooterLegal>
                        <FadeInWhenVisible>
                            <p>
                                &copy; Copyright {year} - 7thSense | Matchbox Media | 7thSense is registered in England &amp; Wales No. 05209221. <br />
                                Registered Office: 2 The Courtyard, Shoreham Road, Upper Beeding, Steyning, West Sussex, BN44 3TN. VAT Registration No. GB 846748676
                            </p>
                        </FadeInWhenVisible>
                    </FooterLegal>
                </FooterGrid>            
            </WrapperComponent>
        </Footer>
    )
}

export default FooterComponent