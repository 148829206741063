import { Link } from "react-router-dom";
import WrapperComponent from '../wrapper/wrapper.component'
import { Header } from "./header.styles";
import { ReactComponent as Logo } from './logo.svg';

const HeaderComponent = () => (
    <Header>
        <WrapperComponent>
            <Link to="https://7thsense.one">
                <Logo />
            </Link>
        </WrapperComponent>
    </Header>
)

export default HeaderComponent