import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { fields } from "../../constants/contact-fields";
import { DynamicControl } from "../controls/dynamic-control.component";
import { Error, InputLabel, Item, SubmitButton } from "../controls/controls.styles";
import styled from "@emotion/styled";
import { Product } from "../../constants/products";
import { TOKENS } from "../../constants/tokens";
import { useApplication } from "../../hooks/useApplication";
import { FadeInWhenVisible } from "../../routes/product/product.route";

interface FormProps {
    summary: string | null;
    choice: string | null;
    product: Product | null;
    raw: any | null;
}

interface MailResponse {
    status: string;
    message: string;
}

const SuccessMessage = styled.section`
    h2 {
        font-weight: 200;
        font-size: 5.0rem;
        line-height: 7.0rem;
        letter-spacing: 1.5px;
        color: ${TOKENS.colors.primaryLight};
        margin-bottom: 38px;
    }

    p {
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 4.2rem;
        margin-bottom: 24px;

        a {
            color: currentColor;
        }
    }
`

const ContactForm = styled.section`
    margin-top: 82px;

    h2 {
        font-weight: 200;
        font-size: 5.0rem;
        line-height: 7.0rem;
        letter-spacing: 1.5px;
        color: ${TOKENS.colors.primaryLight};
        margin-bottom: 38px;
    }

    p {
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 4.2rem;
        margin-bottom: 24px;
    }
`
  
export const ContactFormComponent = ({ summary, product, raw, choice }: FormProps) => {
    const formMethods = useForm();
    const [ success, setSuccess ] = React.useState<boolean>(false);
    const { application, setApplication } = useApplication()
    const {
        handleSubmit,
        watch,
        formState: { isSubmitting, errors }
      } = formMethods;

    function onSubmit(data: any, _error: any) {
        setApplication({ ...application, loading: true })
        fetch('https://configurator.7thsense.one/api/send.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                contact: data,
                product: product?.prettyName,
                summary: summary,
                choice: choice,
                raw: raw?.data
            })
        })
        .then((response) => {
           if (response?.status === 200) {
            setSuccess(true);
            setApplication({ ...application, loading: false })
           }
        })
        .catch(error => console.error('Something went wrong'))
    }
  
    React.useEffect(() => {
      const subscription = watch((value, { name, type }) => console.log(value, name, type));
      return () => subscription.unsubscribe();
    }, [watch]);

    if (success) {
        return (
            <SuccessMessage>
                <FadeInWhenVisible>
                    <h2>Thank you</h2>
                    <p>Please check your inbox for a copy of your specification.</p>
                    <p>If you need any help, or have any questions, please drop us an email to <a href="mailto:info@7thsense.one">info@7thsense.one</a></p>
                </FadeInWhenVisible>
            </SuccessMessage>
        )
    }
    
    return (
        <ContactForm>
            <FadeInWhenVisible>
                <h2>Send yourself a copy of your custom specification?</h2>
            </FadeInWhenVisible>    
            <FadeInWhenVisible>     
                <p>Simply complete your details below.</p>
            </FadeInWhenVisible>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...formMethods}>            
                    {fields.map((d, i) => (
                        <Item key={i}>
                            <FadeInWhenVisible>
                                <InputLabel htmlFor={d.fieldName}>{d.label}</InputLabel>
                                <DynamicControl {...d} />
                                <ErrorMessage errors={errors} name={d.fieldName} render={({ message }) => <Error>{message}</Error>} />
                            </FadeInWhenVisible>
                        </Item>
                    ))}
                </FormProvider>
                <FadeInWhenVisible>
                    <SubmitButton type="submit" disabled={isSubmitting}>
                        Submit
                        <svg viewBox="0 0 24 24">
                        <path d="M 1 5 L 1 1 L 23 12 L 1 23 L 1 19"></path>
                        </svg>
                    </SubmitButton>
                </FadeInWhenVisible>
            </form>
        </ContactForm>
    )
};