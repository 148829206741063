import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ErrorPage } from "./App.styles";
import FooterComponent from "./components/footer/footer.component";
import HeaderComponent from "./components/header/header.component";
import LoadingComponent from "./components/loading/loading.component";
import WrapperComponent from "./components/wrapper/wrapper.component";
import { ApplicationContext } from "./contexts/application.context";
import ProductRoute from "./routes/product/product.route";

export default function App() {
  const [application, setApplication] = React.useState({
    loading: false
  });

  return (
    <HelmetProvider>
      <ApplicationContext.Provider value={{ application, setApplication }}>
        <div>
          <HeaderComponent />
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="/" element={<NoMatch />}/>
              <Route path="/product-not-found" element={<NoMatch />}/>
              <Route path="/:path" element={<ProductRoute />}/>
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
          <LoadingComponent />        
          <FooterComponent />
        </div>
      </ApplicationContext.Provider>
    </HelmetProvider>
  );
}


function Home() {
  return (
    <ErrorPage>
      <WrapperComponent>
        <h1>Page not found</h1>
      </WrapperComponent>
    </ErrorPage>
  );
}

function NoMatch() {
  return (
    <ErrorPage>
      <WrapperComponent>
        <h1>Page not found</h1>
      </WrapperComponent>
    </ErrorPage>
  );
}