import * as Styled from './wrapper.styles'
import { ReactNode } from 'react'

interface WrapperComponentProps {
    children: ReactNode
}

const WrapperComponent = ({ children }: WrapperComponentProps) => (
    <Styled.WrapperComponent data-cy="wrapper">
        { children }
    </Styled.WrapperComponent>
)

export default WrapperComponent