import styled from "@emotion/styled";
import { TOKENS } from "../../constants/tokens";

export const Banner = styled.section`
    min-height: 400px;
    height: 96vh;
    padding: 145px 0 145px 0;
    background: url(/background.jpg);
    background-size: cover;
    background-position: center;

    & > section {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        align-items: center;
        
        @media (min-width: ${TOKENS.media.small}px) {
            flex-direction: row;
            text-align: left;
        }
    }

    h1 {
        font-family: ${TOKENS.fonts.statement};
        font-size: 9rem;
        line-height: 10rem;
        @media (min-width: ${TOKENS.media.medium}px) {
            font-size: 12.0rem;
            line-height: 11.0rem;
        }
        @media (min-width: ${TOKENS.media.large}px) {
            font-size: 14.0rem;
            line-height: 12.0rem;
        }
        
        @media (min-width: ${TOKENS.media.small}px) {
            flex: 0 0 50%;
        }

        span {
            color: transparent;
            -webkit-text-stroke: 1px ${TOKENS.colors.primaryLight};
            @media (min-width: ${TOKENS.media.small}px) {
                -webkit-text-stroke: 1.75px ${TOKENS.colors.primaryLight};
            }
            display: block;
        }
    }
`;

export const ProductContent = styled.div`
    padding: 40px 0 65px 0;
`;

export const LeadCopy = styled.p`
    font-family: ${TOKENS.fonts.primary};
    text-transform: uppercase;
    font-style: normal;
    font-weight: 200;
    font-size: 2.6rem;
    line-height: 5.2rem;
    letter-spacing: 0;
    margin-bottom: 45px;
`;

export const Copy = styled.p`
    font-family: ${TOKENS.fonts.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 2.2rem;
    line-height: 3.8rem;
    margin-bottom: 24px;

    a {
        color: currentColor;
    }
`;

export const ContactSection = styled.section`
    margin-top: 80px;
    padding: 80px 0;
    background: url(/contact-bg.jpg);
    background-size: cover;
    background-position: center;
`;

export const FormGrid = styled.section`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .heading {
        margin-bottom: 24px;
        font-size: 4.2rem;
        line-height: 5.4rem;
        letter-spacing: 0px;
        color: ${TOKENS.colors.primary};
    }
`;

export const FormSection = styled.div`
    flex: 0 0 100%;

    @media (min-width: ${TOKENS.media.medium}px) {
        flex: 0 0 65%;
    }
`;

type SummaryProps = {
    open?: boolean
}


export const SummarySection = styled.aside<SummaryProps>`
    background: ${TOKENS.colors.primary};
    flex: 0 0 100%;
    margin-top: 74px;
    border-radius: 4px;
    padding: 24px 24px;
    height: fit-content;
    z-index: 1;
    font-size: 2.4rem;
    line-height: 3.6rem;

    @media (max-width: ${TOKENS.media.medium - 1}px) {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 24px 24px 85px 24px;
        transition: transform 0.2s linear;
        transform: translateY(${(props) => props.open ? `0` : '100%'});
        border-radius: 0;
    }

    @media (min-width: ${TOKENS.media.medium}px) {
        flex: 0 0 30%;
        position: sticky;
        top: 12px;
        margin-top: 0;
    }

    p {
        margin-bottom: 24px;
        font-size: 2.0rem;
    }
`;

export const ToggleSummary = styled.button<SummaryProps>`
    width: 100%;
    font-size: 2.8rem;
    margin-bottom: 24px;
    border: none;
    background: none;
    color: ${TOKENS.colors.text};
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    letter-spacing: 0.26px;

    svg {
        display: none;
    }

    @media (max-width: ${TOKENS.media.medium - 1}px) {
        cursor: pointer;
        position: absolute;
        top: 1px;
        left: 0;
        right: 0;
        background: ${TOKENS.colors.primary};
        padding: 24px 40px 24px 24px;
        transform: translateY(-100%);
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        svg {
            display: block;
            width: 28px;
            height: auto;
            transition: transform 0.2s linear;
            position: absolute;
            top: 50%;
            right: 24px;
            transform: translateY(-50%) rotate(${(props) => props.open ? `0` : '180deg'});
        }
    }
`;

export const ContactButton = styled.button`
    text-align: left;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    background: none;
    color: ${TOKENS.colors.text};
    line-height: 2.7rem;
    letter-spacing: 0.26px;
    margin-top: 24px;
`;