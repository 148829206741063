import { FormResponse } from "../components/form/form.component"
import { calculateMediaDriveSize, mapSampling, formatNumber, calculateMediaBandwidth2, calculateHardDriveCapacity2, calculateOutputBandwidth } from "../services/product.service"
import { generativeFields, mediaFields } from "./product-field-data"
import { DynamicFieldData } from "./types"

export type FormQuestion = {
    key: string,
    title: string,
    helpContent?: string,
    children?: FormQuestion[],
    parent?: string
}

export type FormChoice = {
    key: string,
    title: string,
    helpContent?: string,
    options: FormChoiceOption[]
}

export type FormChoiceOption = {
    key: string,
    title: string,
    fields: DynamicFieldData[],
    formResolver: Function
}

export type ProductMeta = {
    title: string,
    description: string
}

export type Product = {
    key: string, 
    prettyName: string,
    introContent: string,
    content: string,
    meta: ProductMeta,
    image: string,
    link: string,
    choices: FormChoice[]
}

/*
    R-Series 10 - [number of GPUs]GPU - [Media Drive Size] - [if Capture is Yes, use LC here, otherwise skip] - [if Genlock is Yes, use G here otherwise skip] - [if SDI or ST2110 are used mark them here otherwise skip] - 8U (2022a)

    For example: 
    R-Series 10 - 1GPU - 3600 - ST2110 - 8U (2022a)
    R-Series 10 - 2GPU - 1800 - LC - G - 8U (2022a)
    R-Series 10 - 1GPU - 7200 - 8U (2022a)
*/
const setGenerativeData = (data: FormResponse) => {
    return `R-Series 10 
        - ${data?.data['gpu_count']}GPU 
        - ${data?.data['media_drive_size']}
        ${data?.data['genlock_required'] ? " - G" : ""}
        - 8U (2022a)`
}

/*
    R-Series 10 Delta - [number of channels] - [Media Drive Size per calculation below] - [if Capture is Yes, use LC here, otherwise skip] - [if Genlock is Yes, use G here otherwise skip] - [if SDI or ST2110 are used mark them here otherwise skip] - [audio setup here, defaults to 8U] (2022a)
    
    The description below should show the media bandwidth and server bandwidth per these calculations:
    Media Bandwidth: [Movie Quantity] Movies of [Movie Resolution] at [Movie frame rate] [Output bit depth] [Color Subsampling] = [Media bandwidth calculation], [Movie duration minutes] minutes = [Hard drive capacity required]
    Server Bandwidth: [Output Quantity] Outputs of [Output Resolution] at [Output frame rate] [Output bit depth] [4:4:4 unless SDI, which is then 4:2:2] = [Output Bandwidth Calculation]

    Output Bandwidth cannot exceed 7530MB/s at any resolution, bit depth, frame rate combination.
    At 60fps, if resolution is 2K, HD or WUXGA, the number of outputs cannot exceed 8
    At 30fps, if resolution is 2K, HD, or WUXGA, the number of outputs cannot exceed 10
    If any of these rules are broken, the message needs to change from the server name and description to:
    “Wow that’s a lot of pixels! Our team would love to talk to you about this requirement. Submit the project name and your contact information below so we can get in touch with you to discuss your project.
*/
const setMediaData = (data: FormResponse) => {
    let movie_width, movie_height, movie_dimensions, output_width, output_height, output_dimensions;
    if (data?.data['movie_resolution'] === 'custom') {
        movie_width = data?.data['custom_movie_width'];
        movie_height = data?.data['custom_movie_height'];
    } else {
        movie_dimensions = (data?.data['movie_resolution'] as string).split("x");
        movie_width = movie_dimensions[0];
        movie_height = movie_dimensions[1];
    }

    if (data?.data['output_resolution'] === 'custom') {
        output_width = data?.data['custom_output_width'];
        output_height = data?.data['custom_output_height'];
    } else {
        output_dimensions = (data?.data['output_resolution'] as string).split("x");
        output_width = output_dimensions[0];
        output_height = output_dimensions[1];
    }  

    const mediaBandwidth = calculateMediaBandwidth2(Number(data?.data['movie_count']), Number(movie_width), Number(movie_height), Number(data?.data['bit_rate']), mapSampling(data?.data['colour_subsampling'] as string), Number(data?.data['output_frame_rate']));
    const mediaCapacity = calculateHardDriveCapacity2(Number(data?.data['movie_count']), Number(output_width), Number(output_height), Number(data?.data['bit_rate']), mapSampling(data?.data['colour_subsampling'] as string), Number(data?.data['output_frame_rate']), Number(data?.data['total_duration']));
    const outputCapacity = calculateOutputBandwidth(Number(data?.data['output_number']), Number(output_width), Number(output_height), Number(data?.data['output_frame_rate']), Number(data?.data['bit_rate']));
    const mediaDriveSize = calculateMediaDriveSize(outputCapacity);

    const isValid = () => {
        return (
            ((data?.data['output_frame_rate'] === '30') && (["4096x2160", "3840x2160", "1920x1200"].includes(data?.data['output_resolution'] as string)) && data?.data['output_number'] > 8) || 
            ((data?.data['output_frame_rate'] === '60') && (["4096x2160", "3840x2160", "1920x1200"].includes(data?.data['output_resolution'] as string)) && data?.data['output_number'] > 10) ||
            (mediaBandwidth > 7530) ||
            (mediaDriveSize === -1)
        )
    }

    const validate = () => {
        // Rules for failing validation of the form...
        if (isValid()) {
            return `
                <p>Wow that’s <i>a lot</i> of pixels! Our team would love to talk to you about this requirement. Submit the project name and your contact information below so we can get in touch with you to discuss your project.</p?>
            `;
        } else {
            return "";
        }
    }
    
    return `
        ${validate()}

        ${
            isValid() ? 
                ""
            :
                `
                    <p>
                        R-Series 10 Delta 
                        - ${data?.data['output_number']}
                        - ${mediaDriveSize}
                        ${data?.data['capture_required'] === "yes" ? " - LC" : ""}
                        ${data?.data['genlock_required'] ? " - G" : ""}
                        ${data?.data['output_types'] === 'SDI' || data?.data['output_types'] === 'ST 2110' ? ` - ${data?.data['output_types']}` : ""}
                        ${data?.data['audio_output'] !== 'None' ? ` - ${data?.data['audio_output']}` : ""} 
                        (2022a)
                    </p>
                `
        
        }
        <p>
            <strong>Media Bandwidth:</strong> 
            ${Number(data?.data['movie_count']) === 1 ? "1 Movie" : `${data?.data['movie_count']} Movies`} of 
            ${formatNumber(Number(movie_width))} x ${formatNumber(Number(movie_height))} at 
            ${data?.data['movie_frame_rate']} FPS 
            ${data?.data['bit_rate']}-bit  
            ${data?.data['colour_subsampling']}: 
            ${mediaBandwidth} MB/s, 
            ${formatNumber(data?.data['total_duration'] as number)} minutes = 
            ${mediaCapacity} GB
        </p>
        <p>
            <strong>Server Bandwidth:</strong> 
            ${Number(data?.data['output_number']) === 1 ? `1 ${data?.data['output_types']} Output` : `${data?.data['output_number']} ${data?.data['output_types']} Outputs`} of 
            ${formatNumber(Number(output_width))} x ${formatNumber(Number(output_height))} at 
            ${data?.data['output_frame_rate']} FPS 
            ${data?.data['bit_rate']}-bit 
            ${data?.data['output_types'] === 'SDI' || data?.data['output_types'] === 'NDI' ? "4:2:2" : "4:4:4"} = 
            ${outputCapacity} MB/s
        </p>
    `
}

const PRODUCT_DATA: Product[] = [
    {
        key: "r-series-10",
        prettyName: "R-Series 10",
        link: "https://7thsense.one/product/r-series-10",
        introContent: "Whether you need a generative or playback media server solution, configure your R-Series 10 specification to exact project requirements below.",
        content: `
            Once the configuration is complete, you can choose to have the details sent to your email address - and to our sales team - so we may follow up with you and provide pricing and further details of the specification.
            <br />
            If you have questions or need help at any time, please contact us on <a href="mailto:info@7thsense.one">info@7thsense.one</a>. 
        `,
        meta: {
            title: "R-Series 10 - 7thSense Product Configurator",
            description: "Configure your 7thSense R-Series 10 to exact project requirements, for a generative media solution, or for media playback with Delta Media Server."
        },
        image: "r10.png",
        choices: [
            {
                key: "base-hardware-or-playback",
                title: "Base Hardware or Media playback?",
                options: [
                    {
                        key: "base-hardware",
                        title: "Base Hardware",
                        fields: generativeFields,
                        formResolver: setGenerativeData
                    },
                    {
                        key: "media",
                        title: "Media playback",
                        fields: mediaFields,
                        formResolver: setMediaData
                    }
                ]
            }
        ]
    }
]

export { PRODUCT_DATA }