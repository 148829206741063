import styled from "@emotion/styled";
import { TOKENS } from "../../constants/tokens";

export const Control = styled.div`
    font-family: ${TOKENS.fonts.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0px;
`;

export const ControlCheckbox = styled.div`
    font-family: ${TOKENS.fonts.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0px;
    position: relative;
    height: 40px;
    margin-bottom: 24px;
`;

export const Error = styled.p`
    color: ${TOKENS.colors.error};
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0px;
    margin-bottom: 24px;
`;

export const Radio = styled.input`
    transform: scale(2);
    transform-origin: 0;
    position: absolute;
    top: 18px;
    left: 0;
`;

export const Checkbox = styled.input`
    transform: scale(2);
    transform-origin: 0;
    position: absolute;
    top: 18px;
    left: 0;
`;

export const InputLabel = styled.label`
    font-family: ${TOKENS.fonts.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 2.6rem;
    line-height: 3.1rem;
    letter-spacing: 0px;
    flex: 0 0 85%;
    margin-bottom: 12px;
`;

export const InputComment = styled.p`
    font-family: ${TOKENS.fonts.primary};
    color: ${TOKENS.colors.primaryLight};
    font-weight: 200;
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-style: italic;
    margin-bottom: 24px;
    
    a {
        color: currentColor;
    }
`;

export const Item = styled.div`
    position: relative;
`;

export const Text = styled.input`
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
    border: 2px solid ${TOKENS.colors.text};
    border-radius: 4px;
    padding: 12px 24px;
    text-overflow: ellipsis;
    background: transparent;
    color: ${TOKENS.colors.text};
`;

export const TextArea = styled.textarea`
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
    border: 2px solid ${TOKENS.colors.text};
    border-radius: 4px;
    padding: 12px 24px;
    background: transparent;
    color: ${TOKENS.colors.text};
`;

export const Select = styled.div`
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;        
    border: 2px solid ${TOKENS.colors.text};
    border-radius: 4px;
    position: relative;
    
    select {
        border: none;
        appearance: none;
        background: transparent;
        color: ${TOKENS.colors.text};
        width: 100%;
        padding: 12px 40px 12px 24px;
        text-overflow: ellipsis;

        option {
            color: #414141;
        }
    }

    .carat {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
`;

export const Label = styled.label`
    position: relative;
    display: block;
    margin-bottom: 16px;
    padding: 12px 0 12px 45px;
`;

export const OptionHeader = styled.header`
    display: flex;
    align-items: center;
`;

export const Toggle = styled.button`
    cursor: pointer;
    margin-left: auto;
    background: none;
    border: none;
    padding: 0;
    margin: 0 0 0 auto;

    svg {
        width: 32px;
        height: 32px;
        color: ${TOKENS.colors.primaryLight};
    }
`;

export const SubmitButton = styled.button`
    margin-top: 24px;
    font-size: 35px;
    margin-right: 57px;
    -webkit-text-stroke: 1px white;
    height: 80px;
    line-height: 35px;

    background: none;
    border: none;
    outline: none;
    position: relative;
    text-transform: uppercase;
    float: right;
    color: transparent;
 
   span {
      display: block;
      transition: transform 0.2s
   }
 
   svg {
        height: 80px;
        width: 80px;
        right: -47px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.2s 0.1s;
        position: absolute;

        path {
            stroke: ${TOKENS.colors.primaryLight};
            stroke-width: 0.52px;
            fill: none;
        }
   }
 
   &:hover {
      span {
         transform: translate(15px);
      }
 
      svg {
         transform: translate(15px, -50%);
      }
   } 
`;

export const ToggleControl = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;

        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }

    input { 
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + span {
            background-color: ${TOKENS.colors.primary};
        }
          
        &:focus + span {
            box-shadow: 0 0 1px ${TOKENS.colors.primary};
        }
          
        &:checked + span::before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }
`