import * as React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import WrapperComponent from "../../components/wrapper/wrapper.component";
import { FormChoice, FormChoiceOption, Product, PRODUCT_DATA } from "../../constants/products";
import FullBleedComponent from "../../components/full-bleed/full-bleed.component";
import { FormComponent, FormResponse } from "../../components/form/form.component";
import { Banner, ContactSection, Copy, FormGrid, FormSection, LeadCopy, ProductContent, SummarySection, ToggleSummary, ContactButton } from "./product.styles";
import { ContactFormComponent } from "../../components/contact-form/contact.component";
import { ChoiceComponent } from "../../components/controls/choice.component";

const ProductRoute = () => {
    const productID = useParams<{ path: string }>()?.path;
    const [ formData, setFormData ] = React.useState<FormResponse | null>(null)
    const [ product, setProduct ] = React.useState<Product | null>(null)
    const [ selected, setSelected ] = React.useState<string>("");
    const [ prettySummary, setPrettySummary ] = React.useState<string>("");
    const [ summaryOpen, setSummaryOpen ] = React.useState<boolean>(true);
    const summaryRef = React.useRef<null | HTMLDivElement>(null)
    const contactRef = React.useRef<null | HTMLDivElement>(null)
    const navigate = useNavigate();

    const formChange = (data: FormResponse) => {
        setFormData(data)      
        summaryRef?.current?.scrollIntoView()    
    }

    const scrollToContact = () => {
        contactRef?.current?.scrollIntoView();
        setSummaryOpen(false);
    }

    React.useEffect(() => {
        if (formData) {
            product?.choices?.map((choice: FormChoice) => {
                return choice?.options
                    ?.filter((option: FormChoiceOption) => selected === option?.key)
                    ?.map((option: FormChoiceOption) => {
                        const content: string = option?.formResolver(formData) || "Error generating content";
                        setPrettySummary(content);
                    })
            })    
        }  
    }, [formData])

    React.useEffect(() => {
        if (productID != null) {
            const matches = PRODUCT_DATA?.filter((product: Product) => product.key === productID)
            if (matches?.length > 0) {
                setProduct(matches[0])
            } else {
                navigate("/product-not-found");
            }
        }
    }, [productID, navigate])

    return (    
        <>
            <Helmet>
                <title>{product?.meta?.title}</title>
                <meta
                    name="description"
                    content={product?.meta?.description}
                />
            </Helmet>
            <WrapperComponent>
                <FullBleedComponent>
                    <Banner>
                        <WrapperComponent>
                            <FadeInWhenVisible>
                                <h1>
                                    <span>Product Configurator</span>
                                    {product?.prettyName }
                                </h1>       
                            </FadeInWhenVisible>  
                            <FadeInImage>
                                <img src={`/products/${product?.image}`} alt={`The ${product?.prettyName}`} />
                            </FadeInImage>
                        </WrapperComponent>
                    </Banner>
                </FullBleedComponent>

                <ProductContent>
                    <FadeInWhenVisible>
                        <LeadCopy>{product?.introContent}</LeadCopy>
                    </FadeInWhenVisible>
                    <FadeInWhenVisible>
                        <Copy dangerouslySetInnerHTML={{__html: product?.content as string}} />
                    </FadeInWhenVisible>
                    <FadeInWhenVisible>
                        <Copy>
                            <a href={product?.link} target="_blank" rel="noreferrer">Click here for more information on {product?.prettyName}</a>
                        </Copy>
                    </FadeInWhenVisible>
                </ProductContent>

                <FormGrid>
                    <FormSection>
                        <>
                            {
                                product?.choices?.map((choice: FormChoice, index: number) => {
                                    return (
                                        <div key={index}>
                                            <FadeInWhenVisible>
                                                <h2 className="heading">{choice?.title}</h2>
                                                <p>{choice?.helpContent}</p>
                                            </FadeInWhenVisible>            
                                                                
                                            <ChoiceComponent choice={choice} onChange={(e: any) => {
                                                setFormData(null); 
                                                setSelected(e?.target?.value);
                                            }} />
                                            <FormComponent choice={choice} submitFunction={formChange} selected={selected} />                                    
                                        </div>
                                    )
                                })
                            }    
                        </>            
                    </FormSection>

                    {
                        formData &&
                            <SummarySection ref={summaryRef} open={summaryOpen}>
                                <>  
                                    <ToggleSummary onClick={() => setSummaryOpen(!summaryOpen)} open={summaryOpen}>
                                        Your { product?.prettyName } Configuration


                                        <svg width="69px" height="53px" viewBox="0 0 69 53">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                                                <g id="Artboard" transform="translate(-141.000000, -180.000000)" stroke="white" strokeWidth="3">
                                                    <polyline id="Path-2" points="143 182.18885 207 182.18885 175.008 230.18885 151.240361 199.161421"></polyline>
                                                </g>
                                            </g>
                                        </svg>
                                    </ToggleSummary>

                                    <div dangerouslySetInnerHTML={{__html: prettySummary}} />
                                    
                                    <p>
                                        <ContactButton onClick={() => scrollToContact()}>Please get in touch to find out more.</ContactButton>
                                    </p>
                                </>
                            </SummarySection>
                    }
                </FormGrid>   

                {
                    formData &&
                        <FullBleedComponent>
                            <ContactSection>
                                <WrapperComponent>
                                    <FormGrid>
                                        <FormSection ref={contactRef}>
                                            <ContactFormComponent summary={prettySummary} product={product} raw={formData} choice={selected} />  
                                        </FormSection>
                                    </FormGrid>  
                                </WrapperComponent>
                            </ContactSection>
                        </FullBleedComponent>
                }         
            </WrapperComponent>
        </>
    );
}

export default ProductRoute

type Props = {
    children: string | JSX.Element | JSX.Element[]
  }

export const FadeInWhenVisible = (props: Props) =>{
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.75 }}
        variants={{
          visible: { opacity: 1, x: 0, y: 0 },
          hidden: { opacity: 0, x: -100, y: 100 }
        }}
      >
        {props?.children}
      </motion.div>
    );
}

export const FadeInImage = (props: Props) =>{
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.75 }}
        variants={{
          visible: { opacity: 1, x: 0, y: 0 },
          hidden: { opacity: 0, x: 100, y: 100 }
        }}
      >
        {props?.children}
      </motion.div>
    );
  }