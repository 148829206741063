import { ErrorMessage } from "@hookform/error-message";
import React, { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormChoice, FormChoiceOption } from "../../constants/products";
import { DynamicControl } from "../controls/dynamic-control.component";
import { Error, InputComment, InputLabel, Item, OptionHeader, SubmitButton } from "../controls/controls.styles";
import { HelpComponent } from "../help/help.component";
import { DynamicFieldData } from "../../constants/types";
import { FadeInWhenVisible } from "../../routes/product/product.route";

interface FormProps {
  choice: FormChoice | null;
  selected: string;
  submitFunction: Function
}

export type FormData<T extends string | number> = {
  [category in T]: string | number
}

export interface FormResponse {
  key: string;
  valid: boolean;
  data: FormData<string | number>;
}

export const FormComponent = ({ choice, submitFunction, selected }: FormProps) => {
  const formMethods = useForm();
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, errors }
  } = formMethods;

  const onSubmit = (data: FormData<string>, error: any) => {
    const response: FormResponse = {
      key: selected,
      valid: true,
      data: data
    }
    submitFunction(response)
  };

  return (
    <>
      {
        choice?.options?.map((option: FormChoiceOption, idx: number) => {
          if (selected === option?.key) {
            return (                    
              <div key={idx}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormProvider {...formMethods}>
                    {option?.fields.map((d: DynamicFieldData, i) => {
                      if (d?.parent) {
                        let value = watch(d?.parent); 
                        console.info(value);    
                        
                        
                        if (value === "custom" || value === "yes") {
                          return (
                            <Fragment key={i}>
                              <FadeInWhenVisible>
                                <Item>
                                  <OptionHeader>
                                    <InputLabel htmlFor={d.fieldName}>{d.label}</InputLabel>
                                    <HelpComponent helpContent={d?.helpContent || null} />
                                  </OptionHeader>                        
                                  <DynamicControl {...d} />
                                  <ErrorMessage errors={errors} name={d.fieldName} render={({ message }) => <Error>{message}</Error>} />
                                </Item>
                              </FadeInWhenVisible>
                            </Fragment>
                          )
                        } else {
                          return <Fragment key={i}></Fragment>;
                        }
                      }
                      
                      return (
                        <Fragment key={i}>
                          <Item key={i}>
                            <FadeInWhenVisible>
                                <OptionHeader>
                                  <InputLabel htmlFor={d.fieldName}>{d.label}</InputLabel>
                                  <HelpComponent helpContent={d?.helpContent || null} />
                                </OptionHeader>                        
                                <DynamicControl {...d} />
                                <ErrorMessage errors={errors} name={d.fieldName} render={({ message }) => <Error>{message}</Error>} />
                              </FadeInWhenVisible>
                            </Item>
                        </Fragment>
                      )
                    })}
                  </FormProvider>
                  
                  <FadeInWhenVisible>
                    <SubmitButton type="submit" disabled={isSubmitting}>
                      Submit
                      <svg viewBox="0 0 24 24">
                        <path d="M 1 5 L 1 1 L 23 12 L 1 23 L 1 19"></path>
                      </svg>
                    </SubmitButton>
                  </FadeInWhenVisible>
                </form>
              </div>
            )
          }

          return <Fragment key={idx}></Fragment>
        })
      }
    </>
  );
};