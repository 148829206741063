import React from "react";
import { useFormContext } from "react-hook-form";
import { DynamicFieldData } from "../../constants/types";
import { Checkbox, TextArea, Control, ControlCheckbox, InputComment, Label, Radio, Select, Text, ToggleControl } from "./controls.styles";

export const DynamicControl = ({
  inputType,
  fieldName,
  defaultValue,
  comment,
  options = [],
  config = {}
}: DynamicFieldData) => {
  const { register } = useFormContext();

  switch (inputType) {
    case "text":
      return (
        <Control>
            <Text
              type="text"
              {...register(fieldName, config)}
              defaultValue={defaultValue}
            />
            {
              comment &&
                <InputComment>{comment}</InputComment>
            }
        </Control>
      );
    case "textarea":
      return (
        <Control>
            <TextArea
              {...register(fieldName, config)}
              defaultValue={defaultValue}
            />
            {
              comment &&
                <InputComment>{comment}</InputComment>
            }
        </Control>
      );
    case "email":
      return (
        <Control>
            <Text
              type="email"
              {...register(fieldName, config)}
              defaultValue={defaultValue}
            />
            {
              comment &&
                <InputComment>{comment}</InputComment>
            }
        </Control>
      );
    case "select": {
      return (
        <Control>
          <Select>
            <select
              {...register(fieldName, config)}
              defaultValue={defaultValue}
              name={fieldName}
              id={fieldName}
              >
                <option value="">Please select&hellip;</option>
                {options.map((o, index: any) => (
                    <option key={index} value={o.value}>
                        {o.label}
                    </option>
                ))}
            </select>
            <svg width="16px" height="9px" viewBox="0 0 16 9" className='carat'>
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Dropdown" transform="translate(-378.000000, -25.000000)" fill="currentColor">
                      <path d="M392,21.4142136 L392,30 C392,30.5522847 391.552285,31 391,31 L382.414214,31 C381.861929,31 381.414214,30.5522847 381.414214,30 C381.414214,29.7347835 381.51957,29.4804296 381.707107,29.2928932 L390.292893,20.7071068 C390.683418,20.3165825 391.316582,20.3165825 391.707107,20.7071068 C391.894643,20.8946432 392,21.1489971 392,21.4142136 Z" id="Rectangle" transform="translate(386.000000, 25.000000) rotate(45.000000) translate(-386.000000, -25.000000) "></path>
                  </g>
              </g>
            </svg>
          </Select>
          {
            comment &&
              <InputComment>{comment}</InputComment>
          }
        </Control>
      );
    }
    case "checkbox": {
      return (
        <>
          <ControlCheckbox>
              <Checkbox
                  type="checkbox"
                  {...register(fieldName, config)}
                  defaultValue={defaultValue}
                  id={fieldName}
              />
          </ControlCheckbox>
          {
            comment &&
              <InputComment dangerouslySetInnerHTML={{__html: comment}} />
          }
        </>
      );
    }
    case "toggle": {
      return (
        <>
          <ControlCheckbox>
            <ToggleControl>
              <input
                type="checkbox"
                {...register(fieldName, config)}
                defaultValue={defaultValue}
                id={fieldName}
              />
              <span />
            </ToggleControl>
          </ControlCheckbox>
          {
            comment &&
              <InputComment dangerouslySetInnerHTML={{__html: comment}} />
          }
        </>
      );
    }
    case "radio": {
      return (
        <Control>
            {
                options.map((o, index: any) => {
                    return (
                      <>
                        <Label key={index}>
                            <Radio
                                {...register(fieldName, config)}
                                type="radio"
                                value={ o.value }
                                // onChange={(e) => onChange({target: e?.target?.name, value: e?.target?.value})}
                            />
                            { o.label }
                        </Label>
                      </>
                    )
                })
            }
            {
              comment &&
                <InputComment>{comment}</InputComment>
            }
        </Control>
      );
    }
    case "number":
      return (
        <Control>
            <Text
                type="number"
                {...register(fieldName, config)}
                defaultValue={defaultValue}
            />
            {
              comment &&
                <InputComment>{comment}</InputComment>
            }
        </Control>
      );
    default:
      return <></>;
  }
};
