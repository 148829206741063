import { FormChoice } from "../../constants/products";
import { FadeInWhenVisible } from "../../routes/product/product.route";
import { Control, Label, Radio } from "./controls.styles";

export type ChoiceProps = {
  choice: FormChoice,
  onChange: any;
}

export const ChoiceComponent = (props: ChoiceProps) => { 
  return (
    <Control>
      {
          props?.choice?.options.map((o, index: any) => {
              return (
                <FadeInWhenVisible key={index}>
                  <Label>
                      <Radio
                          name="choice"
                          type="radio"
                          value={ o.key }
                          onChange={props?.onChange}
                      />
                      { o.title }
                  </Label>
                </FadeInWhenVisible>
              )
          })
      }
  </Control>
  );
   
};
