import { TOKENS } from '../../constants/tokens';
import { useApplication } from '../../hooks/useApplication';
import { Loader, Spinner } from './loading.styles'

export default function LoadingComponent() {    
    const { application } = useApplication();
    
    return (
        <Loader show={application.loading} data-cy="loading-component">
            <Spinner>                
                <svg width="124px" height="124px" viewBox="0 0 124 124">
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Loading-State" transform="translate(-571.000000, -517.000000)">
                            <g id="Group" transform="translate(571.000000, 517.000000)">
                                <path d="M62,124 C96.2416545,124 124,96.2416545 124,62 C124,27.7583455 96.2416545,5.68434189e-14 62,5.68434189e-14 C27.7583455,5.68434189e-14 0,27.7583455 0,62 C0,96.2416545 27.7583455,124 62,124 Z M62.5,109 C36.8187591,109 16,88.1812409 16,62.5 C16,36.8187591 36.8187591,16 62.5,16 C88.1812409,16 109,36.8187591 109,62.5 C109,88.1812409 88.1812409,109 62.5,109 Z" id="Back-circle" fill={TOKENS.colors.primary}></path>
                                <path d="M107.573581,71.2081605 C108.454994,66.9891811 112.585524,64.2844175 116.79938,65.1669018 C121.013235,66.0493862 123.714713,70.1849396 122.833301,74.403919 C116.83487,103.116078 91.4529658,124 61.7949843,124 L62.4455484,123.995886 C62.3605624,123.998622 62.2752334,124 62.1895824,124 C62.1131989,124 62.0368381,123.999862 61.9605002,123.999586 L61.7949843,124 C61.7376685,124 61.6804973,123.999381 61.6234768,123.998148 C42.1804945,123.823752 24.2546222,114.705332 12.6175927,99.565641 C9.98935015,96.1463165 10.6315361,91.2444765 14.0519552,88.617075 C17.4723743,85.9896736 22.3757833,86.631654 25.0040258,90.0509786 C33.8279736,101.530871 47.4337747,108.384163 62.1895824,108.384163 C62.2427662,108.384163 62.2958258,108.384695 62.3487563,108.385753 L62.543469,108.385153 C84.4625346,108.038251 103.12922,92.4815895 107.573581,71.2081605 Z" id="2-3" fill={TOKENS.colors.text}></path>
                                <path d="M61.7777778,92.4828475 C96.1421622,92.4828475 124,64.8694267 124,30.8065459 C124,21.7708899 122.033494,12.9959445 118.287326,4.96025284 C116.485434,1.09511634 111.863665,-0.590289522 107.964317,1.19579348 C104.064968,2.98187648 102.364644,7.56309505 104.166537,11.4282316 C106.972231,17.4465659 108.444444,24.0158801 108.444444,30.8065459 C108.444444,56.0982349 87.9668443,76.6490871 62.5494964,77.057575 L61.7777778,77.0637721 C57.4822297,77.0637721 54,80.5154497 54,84.7733098 C54,89.0311699 57.4822297,92.4828475 61.7777778,92.4828475 Z" id="1-3" fill={TOKENS.colors.text} transform="translate(89.125000, 46.241424) scale(1, -1) translate(-89.125000, -46.241424) "></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </Spinner>
        </Loader>
    )
}