
export const prettyBytes = (num: number, precision = 3, addSpace = true): string => {
    const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (Math.abs(num) < 1) return num + (addSpace ? ' ' : '') + UNITS[0];
    const exponent = Math.min(Math.floor(Math.log10(num < 0 ? -num : num) / 3), UNITS.length - 1);
    const n = Number(((num < 0 ? -num : num) / 1000 ** exponent).toPrecision(precision));
    return (num < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent];
};

export const formatNumber = (i: number) => {
    return Math.ceil(i);
}

// Resolution Height * Resolution Width * Quantity of Movies * Output Bit Depth * [Color subsampling ratio] / 8 / 1024 / 1024. Presented in MB/s
export const calculateMediaBandwidth = (height: number, width: number, quantity: number, bitDepth: number, sampling: number, frameRate: number): number => {
    const sum = Math.round((((height * width) * (bitDepth / 8) * sampling)) / (1024 * 1024) * frameRate);
    return sum;
}

export const calculateMediaBandwidth2 = (movieCount:number, height: number, width: number, bitDepth: number, sampling: number, frameRate: number): number => {
    const sum = Math.ceil(((bitDepth * movieCount * height * width * frameRate * sampling / 8) / 1024) / 1024);
    return sum;
}

// [Media Bandwidth] * [Media duration minutes] * 60 / 1024
export const calculateHardDriveCapacity = (mediaBandwidth: number, duration: number): number => {
    return (mediaBandwidth * duration) * 60 / 1024;
}

export const calculateHardDriveCapacity2 = (movieCount:number, height: number, width: number, bitDepth: number, sampling: number, frameRate: number, duration: number): number => {
    const sum = Math.ceil((((bitDepth * movieCount * height * width * frameRate * sampling * 60 * duration / 8) / 1024) / 1024) / 1024);
    return sum;
}

export const calculateOutputBandwidth = (output_count: number, width: number, height: number, output_frame_rate: number, bitDepth: number): number => {
    const sum = Math.ceil((output_count * width * height * output_frame_rate * bitDepth) * 3 / 8 / 1024 / 1024);

    return sum;
}

// Returns the next largest available size from the available options
export const calculateMediaDriveSize = (capacity: number): number => {
    const options = [450, 900, 1800, 3600, 7200, 14400, 28800, 57600];

    for (let i = 0; i < options.length; i++) {
        if (options[i] > capacity){
            return options[i];
        }
    }

    return -1; 
}

export const mapSampling = (sampling: string): number => {
    let result = 0;
    switch (sampling) {
        case "4:4:4":
            result = 3;
        break;
        case "4:4:4:4 (with alpha)":
            result = 4;
        break;
        case "4:2:2":
            result = 2;
        break;
        case "4:2:0":
            result = 1.5;
        break;
        case "NotchLC":
            result = 0.75;
        break;
    }

    return result;
}