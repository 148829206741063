import { DynamicFieldData } from "./types";

//name, company, job title, email, phone, web address - and tickbox w/link for GDPR / privacy policy

export const fields: DynamicFieldData[] = [
  {
    fieldName: "name",
    inputType: "text",
    label: "Name",
    defaultValue: "",
    config: {
      required: "Please enter your name"
    }
  },
  {
    fieldName: "company",
    inputType: "text",
    label: "Company",
    defaultValue: "",
    config: {
      required: "Please enter your company name"
    }
  },
  {
    fieldName: "email",
    inputType: "email",
    label: "Email",
    defaultValue: "",
    config: {
      required: "Please enter your email address"
    }
  },
  {
    fieldName: "project_name",
    inputType: "text",
    label: "Project name",
    defaultValue: "",
    config: {
      required: "Please enter your project name"
    }
  },
  {
    fieldName: "website",
    inputType: "text",
    label: "Web address",
    defaultValue: "",
    config: {
      required: "Please enter your website"
    }
  },
  {
    fieldName: "expectd_order_date",
    inputType: "text",
    label: "Expected Order Date",
    defaultValue: "",
    config: {
      required: "Please enter an expected order date"
    }
  },
  {
    fieldName: "data_protection",
    inputType: "checkbox",
    label: "Data protection",
    comment: `You agree we may collect, maintain, use and disclose your personal information in accordance with our <a href="https://7thsense.one/privacy-policy/" target="_blank">Personal Data & Cookies Policy<a>.`,
    defaultValue: false,
    config: {
      required: "You must agree to this before submitting this form"
    }
  },
];