export const TOKENS = {
    "colors": {
        "background": "#231E23",
        "text": "#E0E0E0",
        "link": "#E0E0E0",
        "primary": "#2369F5",        
        "primaryLight": "#50C8F0",        
        "error": "#BA1200",        
    },
    "media": {
        "small": 758,
        "medium": 868,
        "large": 1024
    },
    "fonts": {
        "primary": "Aktiv Grotesk",
        "statement": "Rift Demi"
    }
}