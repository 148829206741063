import styled from "@emotion/styled";
import { TOKENS } from "../../constants/tokens";

export const Footer = styled.footer`
    margin: 80px 0;
    
    p {
        font-size: 1.2rem;
        line-height: 2.6rem;
    }

    a {
        color: ${TOKENS.colors.link};
    } 
`;

export const FooterGrid = styled.div`
    @media (min-width: ${TOKENS.media.small}px) {
        display: flex;
        flex-wrap: wrap;   
    }
`;

export const FooterSocial = styled.div`
    margin-left: auto;
    margin-top: 12px;
    @media (min-width: ${TOKENS.media.small}px) {
        margin-top: 0;
    }
`;

export const FooterLegal = styled.div`
    flex: 0 0 100%;
    margin-top: 24px;
`;

export const LogoBox = styled.div`
    flex: 0;

    svg {
        width: 150px;
        height: auto;
    }
`;

export const SocialList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    list-style: none;

    li + li {
        margin-left: 14px;
    }

    a {
        color: @palette-mid-2;
        transition: color 0.2s linear;

        &:hover {
            color: @primary;
        }
}
`;