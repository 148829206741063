import styled from "@emotion/styled";

export const Header = styled.header`
    padding: 30px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;

    svg {
        width: 150px;
        height: auto;
    }
`;