import styled from "@emotion/styled";
import { TOKENS } from "./constants/tokens";

export const ErrorPage = styled.section`
    margin-top: 40%;
    min-height: calc(60vh - 70px);
    @media (min-width: ${TOKENS.media.small}px) {
        margin-top: 20%;
        min-height: calc(100vh - 70px);
    }

    h1 {
        font-family: ${TOKENS.fonts.primary};
        font-style: normal;
        font-weight: bold;
        font-size: 5.5rem;
        line-height: 6.1rem;
        @media (min-width: ${TOKENS.media.small}px) {
            font-size: 11.5rem;
            line-height: 10.1rem;
        }
        letter-spacing: 0px;
        text-transform: uppercase;
    }
`;