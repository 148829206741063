import * as Styled from './full-bleed.styles'
import { ReactNode } from 'react'

interface FullBleedComponentProps {
    children: ReactNode
}

const FullBleedComponent = ({ children }: FullBleedComponentProps) => (
    <Styled.FullBleedComponent data-cy="wrapper">
        { children }
    </Styled.FullBleedComponent>
)

export default FullBleedComponent