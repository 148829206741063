import styled from "@emotion/styled";
import FsLightbox from "fslightbox-react";
import React from "react";
import { TOKENS } from "../../constants/tokens";
import { Toggle } from "../controls/controls.styles"

interface FormProps {
    helpContent: string | null
}

const HelpContent = styled.div`
    font-family: ${TOKENS.fonts.primary};
    font-style: normal;
    font-weight: 200;
    font-size: 3.0rem;
    line-height: 5.0rem;
    letter-spacing: 1.5px;
    padding-top: 30px;
    padding-bottom: 30px;
`

export const HelpComponent = ({ helpContent }: FormProps) => {
  const [toggler, setToggler] = React.useState(false);

    if (helpContent != null) {
        return (
            <>
                <Toggle type="button" onClick={() => setToggler(!toggler)} aria-label="See help for this item">
                    <svg width="64" height="56" viewBox="0 0 64 56" xmlns="http://www.w3.org/2000/svg"><path d="M32 0c17.6731 0 32 12.536 32 28S49.6731 56 32 56C14.3269 56 0 43.464 0 28S14.3269 0 32 0Zm2.6 36.456h-5.652V42H34.6v-5.544Zm-2.7-20.772c-1.32 0-2.514.222-3.582.666-1.068.444-1.98 1.062-2.736 1.854-.756.792-1.344 1.728-1.764 2.808-.3733.96-.5902 2.0053-.6507 3.136l-.0153.428h5.292c0-.648.072-1.254.216-1.818.144-.564.366-1.056.666-1.476.3-.42.684-.756 1.152-1.008.468-.252 1.026-.378 1.674-.378.96 0 1.71.264 2.25.792s.81 1.344.81 2.448c.024.648-.09 1.188-.342 1.62-.252.432-.582.828-.99 1.188-.408.36-.852.72-1.332 1.08-.48.36-.936.786-1.368 1.278-.432.492-.81 1.086-1.134 1.782-.288.6187-.4764 1.37-.5653 2.2542l-.0287.3378v1.62h4.86v-1.368c.096-.72.33-1.32.702-1.8.372-.48.798-.906 1.278-1.278s.99-.744 1.53-1.116c.54-.372 1.032-.822 1.476-1.35.444-.528.816-1.164 1.116-1.908.3-.744.45-1.692.45-2.844 0-.696-.15-1.446-.45-2.25-.3-.804-.798-1.554-1.494-2.25s-1.614-1.278-2.754-1.746c-1.14-.468-2.562-.702-4.266-.702Z" fill="currentColor" fillRule="evenodd"/></svg>
                </Toggle>
                <FsLightbox
                    toggler={toggler}
                    sources={[
                        <HelpContent dangerouslySetInnerHTML={{__html: helpContent}} />
                    ]}
                />
            </>
        );
    }

    return <></>
};