import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

type LoaderProps = {
	show: boolean
}

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div<LoaderProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 3;
  opacity: ${ props => props.show ? '1' : '0' };
  transition: opacity 0.3s ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
  pointer-events: ${ props => props.show ? 'all' : 'none' };
`

export const Spinner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  

  svg {
    animation: ${rotate} 1s linear infinite;
  }
`